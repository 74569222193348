import axios from "axios";
import md5 from 'js-md5'

// 拦截器
const instance = axios.create({
    headers: {
        'Content-Type': 'application/json',
        timeout: 30000,
    }
});

// 请求拦截器
instance.interceptors.request.use(config => {
    console.log("🚀 ~ file: requset.js ~ line 13 ~ config", config)
    const data = JSON.stringify(config.data) + ':synjones1234567890';
    config.headers.sign = md5(data)
    const token = window.localStorage.getItem('LOGIN_TOKEN')
    console.log("🚀 ~ file: requset.js ~ line 18 ~ token", token)
    config.headers['Access-Token'] = token ? token : ''
    return config
}, error => {
    console.log(error, 'error');
})
// 响应拦截器
instance.interceptors.response.use((res) => {
    if (res.status === 200) {
        const json = typeof (res.data) === 'string' ? JSON.parse(res.data) : res.data;
        return json
    }
}, (err) => {
    return Promise.reject(err)
})

export default instance;

export const get = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.get(axios.defaults.baseURL + '/api/' + url, {
            params: data
        }).then(response => {
            resolve(response);
        }).catch(() => {
            reject({
                code: '500',
                message: '网络异常，请重试'
            })
        })
    })
}
export const post = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.post(axios.defaults.baseURL + '/api/' + url, data).then(response => {
            resolve(response);
        }).catch(() => {
            reject({
                code: '500',
                message: '网络异常，请重试'
            })
        })
    })
}

export const put = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.put(axios.defaults.baseURL + '/api/' + url, data).then(response => {
            resolve(response);
        }).catch(() => {
            reject({
                code: '500',
                message: '网络异常，请重试'
            })
        })
    })
}
export const deleteFn = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.delete(axios.defaults.baseURL + '/api/' + url, data).then(response => {
            resolve(response);
        }).catch(() => {
            reject({
                code: '500',
                message: '网络异常，请重试'
            })
        })
    })
}
 

